<template>
  <div>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit" ref="_form">
        <div class="is-flex">
          <ValidationProvider
            name="filter_page"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field :type="errors[0] ? 'is-danger' : ''" class="mr-2">
              <b-select v-model="filter.page" placeholder="Página...">
                <option value="doctor">Oftalmologista</option>
                <option value="clinic">Clínica</option>
              </b-select>
            </b-field>
          </ValidationProvider>
          <ValidationProvider
            name="filter_name"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field :type="errors[0] ? 'is-danger' : ''" class="mr-2">
              <b-input v-model="filter.name" placeholder="Nome"></b-input>
            </b-field>
          </ValidationProvider>

          <b-button native-type="submit" type="is-primary">Enviar</b-button>
        </div>
      </form>
    </ValidationObserver>

    <b-table
      @click="setFilter"
      :row-class="(row, index) => row._id === filter._id && 'is-info'"
      :data="filters"
      :columns="columns"
    ></b-table>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Esse campo é requerido",
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      filter: {},
      filters: [],
      columns: [
        {
          field: "id",
          label: "ID",
          visible: false,
        },
        {
          field: "page",
          label: "Página",
        },
        {
          field: "name",
          label: "Nome",
        },
      ],
    };
  },
  created() {
    this.$http.get(`${process.env.VUE_APP_URI}_filter`).then((response) => {
      this.filters = response.data;
    });
  },
  methods: {
    onSubmit() {
      const self = this;

      self.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        if (!self.filter._id)
          self.$http
            .post(`${process.env.VUE_APP_URI}_filter`, self.filter)
            .then((response) => {
              self.$set(self.filter, "_id", response.data._id);
              self.filters.push(self.filter);
              self.$refs._form.reset();
              self.$buefy.toast.open({
                message: "Dados Enviados",
                type: "is-success",
              });
            });

        if (self.filter._id)
          self.$http
            .put(
              `${process.env.VUE_APP_URI}_filter/${self.filter._id}`,
              self.filter
            )
            .then(() => {
              self.filters = self.filters.map(function (v) {
                if (v._id == self.filter._id) v = self.filter;
                return v;
              });
              self.$refs._form.reset();
              self.$buefy.toast.open({
                message: "Dados Enviados",
                type: "is-info",
              });
            });
      });
    },
    setFilter(v) {
      this.filter = JSON.parse(JSON.stringify(v));
    },
  },
};
</script>
<style>
tr.is-info {
  background: #167df0;
  color: #fff;
}
</style>